table, th, thead {
  overflow: visible;
}

.hg-grade-table-container {
  padding-top: 100px;
  overflow-x: auto;
  .hg-assignment-table-name {
    position: relative;

    .hg-raw-text {
      white-space: nowrap;
      transform-origin: bottom left;
      transform: translateX(40px) rotate(-22deg);
      transition: all 1s;
      max-width: 80px;
      overflow: visible;
    }

    &:hover {
      .hg-raw-text {

      }
    }
  }

  th {
    white-space: nowrap;

  }
}