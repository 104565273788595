.character-by-character {

  //shared by plain and styled
  font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace;

  u {
    text-decoration: none;
    line-height: 1.5rem;
    box-sizing: border-box;

    &.special {
      width: auto;
      padding: 0 0.125rem;
      border: 2px solid black;
      box-sizing: border-box;

      &.enter {
        text-transform: uppercase;
      }

      &.tab {
        padding-left: 2rem;
        padding-right: 2rem;
      }

      &.beyond-teacher-output {
        background-color: transparent !important;
        text-transform: uppercase;
        transform: scale(1);
        animation: pulse 1.5s infinite;
        padding-left: 1rem;
        padding-right: 1rem;
      }

      @keyframes pulse {
        0% {
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
        }

        70% {

          box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
        }

        100% {
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
      }

    }
  }

  //only for plain
  &.plain {
    u {
      display: inline-block;
      font-size: 1.25rem;
      margin: 0.2rem 0;


      &.special {
        margin: 0 0.25rem;
        font-size: 1rem;
        font-weight: bold;
      }

      &.input {
        color: blueviolet;
        border-color: blueviolet;
      }
    }
  }

  //only for styled
  &:not(.plain) {
    u {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 1.5rem;
      margin: 1px 0;
      height: 30px;

      &.special {
        margin: 0 2px;
      }

      &:not(.special) {
        //height: 30px;
      }

      //default is "output" (because it's the most common
      //override below
      //background-color: $testCaseSuccess;

      &.input {
        background-color: $testCaseInput;
      }

      &.mismatch {
        background-color: $testCaseFail;
      }

      &.match {
        //background-color: lawngreen;
      }
    }
  }
}