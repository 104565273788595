#hypergrade-grommet-root #privacy {
  main {
    @extend .limiter;
    max-width: 816px;
    padding: $pad1;

    @media (min-width: $bp-lg) {
      padding: 0;
    }

    h1 {
      margin-bottom: 15px;
    }

    h2 {
      margin-bottom: 5px;
      font-size: 20px;
    }

    h3 {
      margin: 15px 0;
      font-size: 16px;
    }

    p, li {
      font-size: 16px;
      line-height: 22px;
    }

    p {
      margin-bottom: 20px;
    }

    ul {
      margin-bottom: 20px;
      li {
        margin-bottom: 5px;
        p {
          margin-bottom: 0;
        }
        p + p {
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }
    }

    p + p {

    }

    ul {
      padding-left: 25px;
    }

    .last-updated {
      font-weight:bold;
    }
    hr {
      margin-bottom: 36px;
    }
  }
}