

footer.site-footer {
  background-color: $color2;
  padding: $pad1;

  @media (min-width: $bp-md) {
    padding: 10px 0;
  }

  .inner-footer {
    @extend .limiter;
    display: flex;
    flex-direction: column-reverse;
    color: white;
    font-size: 16px;
    line-height: 24px;

    @media (min-width: $bp-md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      line-height: normal;
    }

    .separator {
      display: none;
      @media (min-width: $bp-md) {
        display: unset;
      }
    }

    br {
      @media (min-width: $bp-md) {
        display: none;
      }
    }

    .left {

    }

    .right {
      ul {
        list-style-type: none;
        margin: 0;
        margin-bottom: 25px;
        padding: 0;

        @media (min-width: $bp-md) {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          padding: 10px 0;
        }

        li {
          a {
            font-weight: 400;
            text-decoration: none;
            color: white;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        li + li {
          margin-top: 10px;
          @media (min-width: $bp-md) {
            margin-top: 0;
            margin-left: 30px;
          }
        }
      }
    }
  }
}