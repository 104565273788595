#page-home {

  .highlight-1 {
    color: $color1;
  }

  .highlight-2 {
    color: $color2;
  }

  .highlight-3 {
    color: $color3;
  }

  main {
    .hero {
      padding-top: 70px;
      background: linear-gradient(145.89deg, rgba(228, 217, 231, 0.2) 11.76%, rgba(204, 241, 255, 0.2) 117.57%);

      .hero-image-layer {
        background-image: url("../images/home/hero.svg");
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: 25px 405px;


        @media (min-width: $bp-md) {
          background-position: calc(50% + 460px) 38%;
          background-size: auto 150%;
        }

        @media (min-width: $bp-xl) {
          background-position: calc(50% + 460px) -50px;
          background-size: auto;
        }

      }

      .hero-inner {
        @extend .limiter;

        padding: $pad1;
        padding-bottom: 254px;

        @media (min-width: $bp-md) {
          padding: 70px 0;
          display: grid;
          grid-template-columns: 1.3fr 0.7fr;
        }

        @media (min-width: $bp-xl) {
          padding: 150px 0 100px 0;
          grid-template-columns: 1.1fr 0.9fr;
        }

        .left {
          .pre-title {
            font-size: 16px;
            margin-bottom: 20px;

            @media (min-width: $bp-xl) {
              font-size: 22px;
            }

            .highlight-1 {
              font-weight: 900;
            }
          }

          h1 {
            font-size: 35px;
            margin-bottom: 20px;

            @media (min-width: $bp-xl) {
              font-size: 57px;
            }
          }

          .post-title {
            font-size: 18px;
            font-weight: 300;
            line-height: 27px;
            max-width: 560px;
            margin-bottom: 20px;
            color: $color7;

            @media (min-width: $bp-xl) {
              font-size: 21px;
              margin-bottom: 40px;
            }
          }

          form {

            @media (min-width: $bp-xl) {
              display: flex;
            }

            input {
              @extend .basic-shadow;
              font-size: 20px;
              padding: 0 12px;
              border: 0;
              background-color: white;
              border-radius: 0;
              line-height: 54px;

              @media (min-width: $bp-xl) {
                font-size: 24px;
                line-height: 68px;
                padding: 0 25px;
              }
            }

            input[type="email"] {
              width: 100%;
              font-size: 16px;
              margin-bottom: 10px;
              @media (min-width: $bp-xl) {
                width: 500px;
                font-size: 21px;
                margin-bottom: 0;
              }
            }

            input[type="submit"] {
              padding: 0 60px;
              color: white;
              background-color: $color1;
              width: 100%;
              @media (min-width: $bp-xl) {
                border-radius: 0px 8px 8px 0px;
                width: auto;
              }
            }
          }
        }
      }
    }

    .video-block {
      @extend .limiter;
      padding: 35px $pad1 0px $pad1;


      @media (min-width: $bp-xl) {
        display: grid;
        grid-template-columns: 1fr 1.3fr;
        grid-gap: 50px;
        direction: rtl;
        padding: 70px $pad1 25px $pad1;
      }

      & > * {
        direction: ltr;
      }

      .copy-container {

        @media (min-width: $bp-xl) {
          padding: 20px 0;
        }

        h2 {
          margin-bottom: 5px;
          @media (min-width: $bp-xl) {
            font-size: 47px;
          }
        }

        .lead {
          font-size: 18px;
          font-weight: 500;
          color: #1F174C;
          margin-bottom: 10px;
          @media (min-width: $bp-xl) {
            font-size: 21px;
          }
        }

        p {
          font-size: 18px;
          margin-bottom: 20px;
          line-height: 29px;
          font-weight: 400;
          @media (min-width: $bp-xl) {
            line-height: 32px;
            max-width: 500px;
          }

        }
      }

      .video-container {

        @media (min-width: $bp-xl) {
          padding: 5px;
          background-color: black;
          border-radius: 20px;
        }

        iframe {
          @extend .basic-shadow;
          width: 100%;
          height: 49vw;
          max-height: 320px;

          @media (min-width: $bp-xl) {
            height: 320px;
            box-shadow: 16px 14px 20px rgba(0, 0, 0, 0.5);
            border-radius: 20px;
          }
        }
      }
    }

    .easy-steps {
      @extend .limiter;
      text-align: center;
      padding: 50px 0 10px 0;

      @media (min-width: $bp-xl) {
        padding: 50px 0;
      }

      h2 {
        font-size: 30px;
        margin-bottom: 20px;

        @media (min-width: $bp-xl) {
          font-size: 47px;
        }
      }

      h3 {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 40px;

        a {
          color: $color2;
        }

        @media (min-width: $bp-xl) {
          font-size: 21px;
        }
      }

      ul {
        list-style-type: none;
        padding: 0;

        @media (min-width: $bp-md) {
          display: flex;
          justify-content: center;
        }

        li {
          padding: $pad1;

          @media (min-width: $bp-md) {
            padding: 10px;
          }

          @media (min-width: $bp-xl) {
            padding: 0;
            width: 300px;
            margin: 0 40px;
          }

          .icon-wrapper {
            display: inline-flex;
            width: 60px;
            height: 60px;
            background: radial-gradient(268.33% 268.33% at 50% 50%, #FFFFFF 0%, #6951FF 100%);
            justify-content: center;
            align-items: center;
            border-radius: 30px;
            margin-bottom: 26px;

            img {
              height: 30px;
              width: auto;
            }
          }
        }

        h4 {
          font-size: 21px;
          margin-bottom: 10px;
        }

        p {
          font-size: 18px;
          font-weight: 500;
          line-height: 29px;
        }
      }
    }

    .payment {

      @media (min-width: $bp-xl) {
        background-image: url("../images/home/bg1.svg");
        background-repeat: no-repeat;
        background-position: right top;
        margin-bottom: -100px;
      }

      .payment-inner {
        @extend .limiter;
        padding: $pad1;

        @media (min-width: $bp-md) {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 50px;
          padding-bottom: 100px;
          padding-left: 38px;
        }

        @media (min-width: $bp-xl) {
          padding: 156px 0;
        }

        .left {

          padding-bottom: 65px;
          @media (min-width: $bp-md) {
            flex: 1;
          }

          @media (min-width: $bp-md) {
            flex: 1.1;
            display: flex;
            justify-content: flex-end;
            padding: 0;
          }

          .price-pill {

            margin-left: auto;
            width: 300px;
            height: 73px;
            font-size: 26px;
            padding: 0 27px;
            position: relative;
            background: $color2;
            box-shadow: 0px 4px 34px rgba(105, 81, 255, 0.4);
            border-radius: 50px 50px 50px 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-weight: 900;

            @media (min-width: $bp-xl) {
              width: 497px;
              height: 170px;
              font-size: 47px;
              padding: 0 47px;
              margin-left: 0;
              justify-content: flex-end;
            }

            img {
              position: absolute;
              left: -40px;
              top: -60px;
              width: 103px;
              height: auto;
              @media (min-width: $bp-xl) {
                left: -115px;
                top: -148px;
                width: 252px;
                height: auto;
              }
            }
          }
        }

        .right {

          @media (min-width: $bp-md) {
            padding-left: 40px;
            padding-right: 40px;
            flex: 1;
          }

          @media (min-width: $bp-xl) {
            flex: 0.9;
            display: flex;
            justify-content: center;
          }

          p {
            font-weight: 900;
            font-size: 18px;
            line-height: 27px;

            @media (min-width: $bp-md) {
              max-width: 392px;
              font-size: 22px;
            }

            @media (min-width: $bp-xl) {
              font-size: 30px;
              line-height: 34px;
            }
          }
        }
      }
    }

    .step-up {
      padding: 0 $pad1;

      @media (min-width: $bp-md) {
        margin-bottom: 80px;
      }

      @media (min-width: $bp-xl) {
        padding: 150px 0;
        background-image: url("../images/home/bg2.svg");
        background-repeat: no-repeat;
        background-position: calc(50% - 300px) 50%;
        margin-bottom: 0;
      }

      .step-up-inner {
        @extend .limiter;
        position: relative;

        @media (min-width: $bp-md) {
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        @media (min-width: $bp-xl) {
          text-align: left;
          display: flex;
          flex-direction: row;
          justify-content: center;
        }


        .left {
          margin-bottom: 20px;

          @media (min-width: $bp-xl) {
            flex: 1.1;
            display: flex;
            justify-content: flex-end;
            margin-right: 40px;
            margin-bottom: 0px;
          }

          .content {
            h2 {
              font-size: 30px;
              font-weight: 900;
              margin-bottom: 7px;

              @media (min-width: $bp-xl) {
                font-size: 47px;
                margin-bottom: 11px;
              }
            }

            h3 {
              font-size: 21px;
              font-weight: 500;
              color: $color7;
              margin-bottom: 10px;

              @media (min-width: $bp-xl) {
                margin-bottom: 30px;
              }
            }

            p {
              font-size: 18px;
              font-weight: 400;
              line-height: 27px;
              max-width: 500px;

              .highlight-2 {
                font-weight: 500;
              }

              @media (min-width: $bp-xl) {
                line-height: 32px;
              }
            }
          }
        }

        .right {

          @media (min-width: $bp-xl) {
            flex: 0.9;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          img {
            width: 100%;
            height: auto;
            max-width: 500px;

            @media (min-width: $bp-xl) {

            }

          }
        }
      }
    }

    .mini-panels {
      @extend .limiter;
      margin-top: 30px;
      margin-bottom: 30px;
      padding: 0 $pad1;

      @media (min-width: $bp-md) {


      }

      @media (min-width: $bp-xl) {
        margin-top: 80px;
        margin-bottom: 80px;
        display: grid;
        grid-template-columns: 0.8fr 1.2fr;
        grid-gap: 50px;
        padding: 0;
      }

      .left {

        .pill {

          position: relative;

          @media (min-width: $bp-xl) {
            width: 433px;
            height: 284px;
            background-image: url("../images/home/bg3.svg");
            background-position: center center;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 60px 30px 30px 30px;
          }

          h2 {
            font-size: 30px;
            font-weight: 900;
            text-align: center;

            @media (min-width: $bp-md) {
              max-width: 350px;
              margin-left: auto;
              margin-right: auto;
            }

            @media (min-width: $bp-xl) {
              font-size: 37px;
              text-align: left;
              max-width: none;
            }
          }

          img {
            display: none;

            @media (min-width: $bp-xl) {
              position: absolute;
              display: block;
              top: 0;
              right: 0;
            }
          }
        }
      }

      .right {
        padding-top: 10px;

        ol {
          padding-left: $pad1;

          @media (min-width: $bp-md) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding-left: $pad1 * 2;
            padding-right: $pad1 * 2;
            grid-gap: 30px 50px;
          }

          @media (min-width: $bp-xl) {
            grid-gap: 54px 94px;
            padding: 0;
          }

          li {
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: 29px;


            &::marker {
              font-weight: bold;
            }

            @media (min-width: $bp-md) {
              padding-left: 10px;
            }
          }

          li + li {
            margin-top: 20px;
            @media (min-width: $bp-md) {
              margin-top: 0;
            }
          }
        }
      }
    }

    .testimonials {
      @extend .limiter;
      margin-top: 40px;
      margin-bottom: 40px;

      @media (min-width: $bp-md) {

      }

      @media (min-width: $bp-xl) {
        margin-top: 80px;
        margin-bottom: 80px;

      }

      .copy {

        padding: 0 $pad1;

        @media (min-width: $bp-xl) {
          padding: 0;
        }

        p {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 5px;

          @media (min-width: $bp-xl) {
            font-size: 22px;
            margin-bottom: 10px;
            font-weight: 800;
          }
        }

        h2 {
          font-size: 30px;
          font-weight: 800;
          margin-bottom: 10px;
          @media (min-width: $bp-xl) {
            font-size: 47px;
          }
        }

        h3 {
          font-size: 18px;
          font-weight: 300;
          text-transform: uppercase;
          margin-bottom: 20px;

          @media (min-width: $bp-xl) {
            font-size: 27px;
            margin-bottom: 50px;
          }

          .heart {
            color: red;
          }

        }
      }

      .testimonial-cards {
        padding-left: $pad1;
        padding-right: $pad1;

        @media (min-width: $bp-md) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 20px;
        }

        @media (min-width: $bp-xl) {
          padding: 0;
          grid-gap: 50px;
        }

        .testimonial-card {
          @extend .basic-shadow;
          border-radius: 10px;
          position: relative;

          @media (min-width: $bp-xl) {
            display: grid;
            grid-template-columns: 0.8fr 1.2fr;
          }

          &:before {
            pointer-events: none;
            display: none;
            content: '';
            position: absolute;
            width: 205px;
            height: 160px;
            left: 0;
            bottom: 0;
            border-radius: 10px 10px 0px 8px;

            @media (min-width: $bp-xl) {
              display: inline-block;
            }
          }

          &:first-child {
            &:before {
              background: $color4;
            }
          }

          &:last-child {
            background-color: #B2B3FF;

            &:before {
              background: #6951FF;
            }
          }

          .left {
            display: none;
            overflow: hidden;
            max-height: 321px;

            @media (min-width: $bp-xl) {
              display: flex;
              justify-content: center;
              align-items: flex-end;
            }

            img {
              position: relative;
              width: auto;
              height: 350px;
            }
          }

          .right {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px 20px;
            height: 100%;

            .content {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              blockquote {
                margin: 0;
                padding: 0;
                font-size: 18px;
                font-style: italic;
                font-weight: 400;
                line-height: 29px;
                margin-bottom: 20px;
              }

              .name, .title {
                font-style: italic;
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
              }

              .location {
                font-size: 11px;
                font-weight: 400;
                margin-bottom: 20px;
              }
            }
          }
        }

        .testimonial-card + .testimonial-card {
          margin-top: 20px;
          @media (min-width: $bp-md) {
            margin-top: 0;
          }
        }

      }
    }

    .send-assignments {

      margin-bottom: 48px;

      @media (min-width: $bp-xl) {
        margin-top: 120px;
        margin-bottom: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & > img {
        display: none;
        @media (min-width: $bp-xl) {
          display: block;
        }
      }

      .send-assignments-inner {
        background: $color2;
        box-shadow: 0px 8px 43px rgba(0, 0, 0, 0.16);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px $pad1;

        @media (min-width: $bp-md) {
          padding-top: 70px;
          padding-bottom: 70px;
        }

        @media (min-width: $bp-xl) {
          padding: 100px 100px;
          margin-left: 40px;
          margin-right: 40px;
          border-radius: 60px;
        }

        .content {
          text-align: center;

          h2 {
            color: white;
            font-size: 30px;
            font-weight: 900;
            margin-bottom: 10px;


            @media (min-width: $bp-xl) {
              font-size: 47px;
            }
          }

          p {
            font-weight: 400;
            font-size: 18px;
            color: rgba(255, 255, 255, 0.7);
            margin-bottom: 15px;
            @media (min-width: $bp-xl) {
              font-size: 21px;
              margin-bottom: 30px;
            }
          }

          .ctas {
            display: flex;
            flex-direction: column-reverse;
            @media (min-width: $bp-xl) {
              display: block;
            }

            a {
              display: block;
              box-sizing: border-box;
              border-radius: 8px;
              border: 2px solid #FFFFFF;
              text-decoration: none;
              color: white;
              font-weight: 500;
              font-size: 20px;
              line-height: 24px;
              text-transform: capitalize;
              padding: 10px 40px;
              margin: 8px 0;
              @extend .basic-shadow;

              &:first-child {
                border-color: white;
              }

              &:last-child {
                border-color: $color1;
                background-color: $color1;
              }

              @media (min-width: $bp-xl) {
                display: inline-block;
                font-size: 24px;
                line-height: 29px;
                padding: 20px 40px;
                margin: 10px;
              }
            }
          }

        }
      }
    }

    .contact {
      background-color: $color4;
      padding: $pad1;

      @media (min-width: $bp-xl) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 100px;
      }

      .contact-inner {
        @extend .limiter;
        text-align: center;
        max-width: 600px;

        h2 {
          font-size: 30px;
          max-width: 500px;
          margin-bottom: 30px;
          margin-left: auto;
          margin-right: auto;

          @media (min-width: $bp-xl) {
            font-size: 47px;
            margin: 0 auto 40px auto;
          }
        }

        form {

          @media (min-width: $bp-xl) {
            width: 700px;
          }

          label {
            display: none;
          }

          input, textarea {
            font-family: Roboto, sans-serif;
            display: block;
            background: #FFFFFF;
            border: 1px solid $color5;
            box-sizing: border-box;
            border-radius: 14px;
            font-size: 17px;
            line-height: 25px;
            width: 100%;
            padding: 10px 20px;
            margin-bottom: 10px;

            @media (min-width: $bp-xl) {
              margin-bottom: 20px;
            }
          }

          textarea {
            height: 200px;
          }

          input[type="submit"] {
            background-color: $color2;
            color: white;
            font-size: 21px;
            font-weight: 500;

            &:hover {
              cursor: pointer;
            }

            @media (min-width: $bp-xl) {
              width: 50%;
              margin-left: auto;
            }
          }
        }
      }
    }
  }
}
