$opacity: 0.7;
$testCaseSuccess: rgba(85, 255, 85, $opacity);
$testCaseNeutral: rgba(255, 255, 0, $opacity);
$testCaseFail: rgba(255, 136, 136, $opacity);
$testCaseInput: rgba(200, 200, 200, $opacity);

#hypergrade-grommet-root {

}

.hg-green-bg {
  background-color: $testCaseSuccess;
}

.hg-red-bg {
  background-color: $testCaseFail;
}

.hg-yellow-bg {
  background-color: $testCaseNeutral;
}

.show-change {
  animation: highlight ease 3s;
}

@keyframes highlight {
  0% {
    background-color: green;
  }
  100% {
    background-color: transparent;
  }
}

pre {
  margin: 0;
}

.real-name {
  text-transform: capitalize;
}

.page-not-found {
  h1 {
    font-size: 16rem;
    font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace;
    line-height: 12rem;
  }
}

.spin-slow {
  animation: spin linear 10s infinite;
}

.spin {
  animation: spin linear 2s infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#hypergrade-grommet-root {
  p {
    max-width: none;
  }

  .page-privacy {
    p {
      margin: 0.5rem;
    }

    li {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .t1 {
    transition: 1s all;
  }

  .t2 {
    transition: 2s all;
  }

  .t3 {
    transition: 3s all;
  }
}

//Begin without Grommet

$bp-sm: 576px;
$bp-md: 768px; //tablet
$bp-lg: 992px;
$bp-xl: 1200px;
$bp-xxl: 1400px;

@media (min-width: $bp-md) {

}

@media (min-width: $bp-xl) {

}

$color1: #FF5700;
$color2: #6951FF;
$color3: #FFB590;
$color4: #EBFCFF;
$color5: #C6E3E9;
$color6: #EC3727; //for errors
$color7: #1F174C;

$color2Faded: rgba(105, 81, 254, 0.1);

$pad1: 25px;

.limiter {
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $bp-md) {
    max-width: 680px;
  }

  @media (min-width: $bp-xl) {
    max-width: 1260px;
  }
}

.basic-shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

//body {
//  padding-top: 69px; //height of navbar
//  @media (min-width: $bp-xl) {
//    padding-top: 0;
//  }
//}

#hypergrade-grommet-root .super-hg {

  * {
    box-sizing: border-box;
  }

  p, h1, h2, h3, h4, h5, h6 {
    line-height: normal;
    margin: 0;
  }

  a {
    color: $color2;
    text-decoration: none;
  }

  input:not([type="submit"]), textarea {
    background: $color2Faded;
    border-radius: 14px;
    border-color: $color2Faded;
    line-height: 46px;
    padding: 0 15px;
  }

  textarea {
    width: 100%;
    font-family: Arial, monospace;
    line-height: normal;
    min-height: 150px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  p.error {
    color: $color6;
    font-weight: 500;
    margin-top: 5px;

    &.over {
      margin-top: 0;
      margin-bottom: 5px;
    }
  }

  input.error {
    border-color: $color6;
  }

  .hg-button {
    border: 2px solid $color2;
    box-sizing: border-box;
    filter: drop-shadow(0px 10px 30px $color2Faded);
    border-radius: 8px;

    font-size: 19px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    text-decoration: none;
    padding: 12px 30px;
    color: $color2;
    &:hover {
      cursor: pointer;
    }
  }

  .hg-button-1 {
    @extend .hg-button;
    background-color: $color2;
    color: white;
  }

  .hg-button-2 {
    @extend .hg-button
  }

}

