//legacy. Delete when original MenuBar.js is no longer used.
.logo {
  height: 40px;
  display: block;
}

.basic-transition {
  transition: 200ms all;
}

.main-nav {
  z-index: 5000;
  @extend .basic-transition;

  .nav-inner {
    @extend .basic-transition;
  }
}

#hypergrade-grommet-root .super-hg .main-nav.desktop {
  @media (max-width: $bp-xl - 1) {
    display: none;
  }

  .nav-inner {
    @extend .limiter;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 35px 0;

    .left {
      .logo {
        height: 32px;
        width: auto;
      }
    }

    .right {
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style-type: none;

        li {
          a {

          }
        }

        li + li {
          margin-left: 30px;
        }

        .hg-button-1 {
          color: white;
        }
      }
    }
  }

  &.fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;

    &.scrolled {
      background-color: white;
      @extend .basic-shadow;

      .nav-inner {
        padding: 15px 0;
      }
    }
  }
}

#hypergrade-grommet-root .super-hg .main-nav.mobile {
  @media (min-width: $bp-xl) {
    display: none;
  }
  display: flex;
  justify-content: center;
  align-content: center;
  height: 70px;
  padding: 0px 20px;

  button {
    border: 0;
    background: 0;
  }

  .nav-inner {
    @extend .limiter;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;

    .left {
      svg {
        height: 20px;
        width: auto;
      }
    }

    .right {
      button {
        background-color: $color2Faded;
        display: flex;
        justify-content: center;
        align-content: center;
        border-radius: 5px;
        padding: 5px;

        svg {
          stroke: $color2;
        }
      }
    }
  }

  .flyout {
    @extend .basic-transition;
    @extend .basic-shadow;
    position: fixed;
    top: -100vh;
    left: 0;
    right: 0;
    background-color: $color2;
    color: white;

    &.active {
      top: 0;
    }

    .flyout-inner {
      padding: $pad1;

      .close-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;

        svg {
          stroke: white;
          fill: white;
          height: 20px;
          width: auto;
        }
      }

      ul.links {
        list-style-type: none;
        padding: 0;

        li {
          margin-bottom: 40px;

          a {
            color: white;
          }
        }


      }

      hr {
        margin-bottom: 30px;
        border-color: rgba(255, 255, 255, 0.5);
      }

      .ctas {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;

        .hg-button-1 {
          border-color: white;
        }

        .hg-button-2 {
          background-color: $color1;
          color: white;
          border-color: $color1;
        }

        .hg-button-1, .hg-button-2 {
          padding-top: 5px;
          padding-bottom: 5px;
          font-weight: 400;
          font-size: 16px;
        }
      }

    }
  }

  &.fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background-color: transparent;

    &.scrolled {
      @extend .basic-shadow;
      background-color: white;

      .nav-inner {

      }
    }
  }

}