
.course-list {
  .stat {
    font-size: 5rem;
    line-height: 5rem;
  }

  .course-code {
    font-size: 0.8rem;
  }
}