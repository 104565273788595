#form-frame {
  min-height: 100%;

  @media (min-width: $bp-md) {
    display: flex;
  }

  .branding-block {
    background-color: $color2;
    padding: 32px $pad1;
    height: 159px;
    box-sizing: border-box;

    @media (min-width: $bp-md) {
      height: auto;
      display: flex;
      flex-direction: column;
      background-image: url("../images/form-frame/bg.svg");
      background-size: cover;
      background-position: bottom left;

      //spacer so the .copy-container is perfectly aligned in center of flex
      &:after {
        display: block;
        content: '';
        height: 27px;
      }
    }

    @media (min-width: $bp-xl) {
      width: 620px;
    }

    .logo-container {
      svg {
        fill: white;
        height: 27px;
        width: auto;
      }
    }

    .copy-container {
      display: none;
      flex: 1;
      align-items: center;
      @extend .basic-transition;

      @media (min-width: $bp-lg) {
        display: flex;
        justify-content: center;
      }

      .copy {
        color: white;
        max-width: 329px;

        h1 {
          font-size: 58px;
          font-weight: 500;
          margin-bottom: 40px;
        }

        p {
          font-size: 18px;
        }
      }
    }
  }

  .form-container {
    flex: 1;
    margin: auto;
    justify-content: center;
    align-items: center;
    padding: 60px $pad1 20px $pad1;

    .form-frame-theme {
      max-width: 414px;
      margin: 0 auto;

      p {
        font-size: 16px;
      }

      p + p {
        margin-top: 8px;
      }

      .lead {
        margin-bottom: 30px;

        h1 {
          font-size: 30px;
          margin-top: 0;
          margin-bottom: 12px;
        }

        h1, p {
          text-align: left;

          @media (min-width: $bp-lg) {
            text-align: center;
          }
        }
      }

      form {
        margin-bottom: 16px;

        .form-group {
          margin-bottom: 28px;

          label {
            display: block;
            font-weight: bold;
            font-size: 17px;
            line-height: 20px;
            margin-bottom: 9px;
          }

          input:not([type="radio"]) {
            display: block;
            width: 100%;
            height: 46px;
          }

          input:not([type="radio"]), textarea {
            font-size: 14px;
          }
        }

        .form-group.iama {
          display: flex;

          label {
            margin-bottom: 0;
          }

          .radio-button-group {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            label {
              font-weight: normal;
              margin-left: 8px;
              font-size: 18px;
            }

            input {
              margin: 0;
            }

            label + input {
              margin-left: 20px;
            }
          }
        }

        .helper-text {
          margin-top: 5px;
          text-align: right;
          font-weight: 500;
          font-size: 16px;
        }

        input[type="submit"] {
          border-radius: 23px;
          font-size: 20px;
          font-weight: 700;
        }
      }
    }

  }

}